import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI, {
	Message
} from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import { i18n } from '../i18n/index'
const bus = new Vue();
Vue.prototype.$bus = bus

import Axios from 'axios'
Vue.config.productionTip = false
// step2：把axios挂载到vue的原型中，在vue中每个组件都可以使用axios发送请求,
// 不需要每次都 import一下 axios了，直接使用 $axios 即可
Vue.prototype.$axios = Axios

// step3：使每次请求都会带一个 /api 前缀  // https://YYCSGO.com/   
// Axios.defaults.baseURL = 'https://YYCSGO.com/'    // '/api'   https://ahyltt.com.com/
Axios.defaults.baseURL = '/'
Axios.defaults.timeout = 60000;
Axios.defaults.retry = 3;


Axios.interceptors.request.use((config) => {
	// const token = store.state.token
	const token = localStorage.getItem('token');
	if(config.url!='/api/index/lately' || token) {
		store.commit('openLoading',true)
	}
	if (token) {
		config.headers['token'] = token
	}
	return config
}, config => {
	return Promise.reject(config)
})

Axios.interceptors.response.use((response) => {
	store.commit('closeLoading',false)
	// console.log(response,'response')
	const code = response.data.code
	if (![1,0].includes(code)) {
		if (code == 401 || response.data.msg=="玩家信息不存在"|| response.data.msg == "用户信息有误" || response.data.msg=="用户不存在" || response.data.msg=="token不存在") {
			
			console.log("所以是来这里了？？？？",response)
			if(localStorage.getItem("userInfo")){
				localStorage.setItem("userInfo", null);
			}
			store.commit("getLogin", true);
			store.commit("getLogins", false);
			store.commit("loginOut");
			Message.error('请先登录')
		}
		return Promise.reject(response)
		// return response
	}
	return response
}, (err) => {
	const {response} = err
	// console.log(response,'101010101010')
	if(response.status == 401) {
		console.log("所？？？",response)
		store.commit("getLogin", true);
		store.commit("getLogins", false);
		store.commit("loginOut");
	}
	
	return Promise.reject(err)
	// return response
})

import qs from 'qs';
// Vue.prototype.$ajax = axios // 把axios换成$ajax变量
Vue.prototype.$qs = qs;

//修改网页标题
Vue.directive('title', {
	inserted: function(el, binding) {
		document.title = el.dataset.title
	}
})


Vue.config.productionTip = false
Vue.use(ElementUI);
// 暴露 i18n 实例作为全局变量
window.i18n = i18n;
new Vue({
	router,
	store,
	i18n,
	render: function(h) {
		return h(App)
	}
}).$mount('#app')