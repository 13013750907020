import { render, staticRenderFns } from "./Openbox.vue?vue&type=template&id=6d15f527&scoped=true&"
import script from "./Openbox.vue?vue&type=script&lang=js&"
export * from "./Openbox.vue?vue&type=script&lang=js&"
import style0 from "./Openbox.vue?vue&type=style&index=0&id=6d15f527&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d15f527",
  null
  
)

export default component.exports