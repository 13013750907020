<template>
  <div class="slide">
    <ul class="slide-ul" v-if="showNav">
      <li
        v-for="(item1, index1) in listSlide"
        :key="index1"
        @click="getBox(item1.box_id)"
      >
        <div class="border" :class="'color' + item1.color"></div>
        <div class="slide-warp1">
          <div class="bg-img">
            <img :src="item1.box_image" alt="" />
          </div>
          <div class="left">
            <img :src="item1.player_img" />
          </div>
          <div class="right">
            <h4 class="r-2 h4">{{ item1.player_name }}</h4>
          </div>
          <div class="time">
            {{ timeAgo(item1.createtime) }}
          </div>
        </div>
        <div
          class="slide-warp"
          :style="{
            backgroundImage: 'url(' + getColorPz(item1.color) + ')',
          }"
        >
          <div class="left">
            <img :src="item1.imageUrl" />
          </div>
          <div class="right">
            <!-- <span class="r-2 h4">{{ item1.shortName }}121212232</span> -->
            <span class="r-2 h4">{{ getLanguage(item1) }}</span>
          </div>
        </div>
      </li>
    </ul>
    <ul class="slide-ul" v-else>
      <li
        v-for="(item1, index1) in listSlide"
        :key="index1"
        @click="getBox(item1.box_id)"
        :style="{
          backgroundImage: 'url(' + getColorPz(item1.color) + ')',
        }"
      >
        <div class="slide-warp2">
          <div class="right">
            <span class="r-2 h4 iii" style="color: white; font-size: 8px">{{ getLanguage(item1) }}</span>
          </div>
          <div class="left">
            <img :src="item1.imageUrl" />
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      listSlide: [],
      language: localStorage.getItem("lang") ||this.$store.state.currentLanguage ||"zh",
    };
  },
  props: ["showNav"],
  methods: {
    getLanguage(item) {
      const lang = this.language;
      console.log(lang)
      switch (lang) {
        case "zh":
          return item.shortName;
        case "en":
          return item.english_shortName;
        default:
          return item.shortName;
      }
    },
    timeAgo(item) {
      const now = moment(); // 获取当前时间
      const time = moment(item, "YYYY-MM-DD HH:mm:ss"); // 解析时间字符串，指定格式

      const diffInMinutes = now.diff(time, "minutes"); // 计算分钟差
      if (diffInMinutes < 60) {
        return `${diffInMinutes}min ago`;
      }

      const diffInHours = now.diff(time, "hours"); // 计算小时差
      if (diffInHours < 24) {
        return `${diffInHours}h ago`;
      }

      const diffInDays = now.diff(time, "days"); // 计算天数差
      return `${diffInDays}d ago`;
    },
    getList() {
      let param = {
        page: 1,
        pagesize: 28,
      };
      var that = this;
      this.$axios
        .post("/api/index/lately", this.$qs.stringify(param))
        .then((res) => {
          let data = res.data.data;
          this.listSlide = [];
          if (res.data.code == 1) {
            var thispage = 0;
            for (let i = 0; i < data.data.length; i++) {
              this.listSlide.push(data.data[i]);
            }
            var ontimeCount = 4;
            var cishu = data.data.length / ontimeCount;
            var thislist = JSON.parse(JSON.stringify(data.data));

            for (var z = 0; z < cishu; z++) {
              var c = 0;
              setTimeout(function () {
                that.listSlide.splice(-ontimeCount, ontimeCount);
                that.$forceUpdate();
                for (let is = 4 * c; is < c * ontimeCount + 4; is++) {
                  thislist[is]["news"] = true;
                  that.listSlide.unshift(thislist[is]);
                }
                setTimeout(function () {
                  that.listSlide.forEach((e) => {
                    e["news"] = false;
                  });
                  that.$forceUpdate();
                }, 2000);
                c++;
                that.$forceUpdate();
              }, 5000 * z);
            }
            setInterval(function () {
              that.$axios
                .post("/api/index/lately", that.$qs.stringify(param))
                .then((res) => {
                  let data = res.data;
                  if (data.code == 1) {
                    for (var z = 0; z < cishu; z++) {
                      var c = 0;
                      setTimeout(function () {
                        that.listSlide.splice(-ontimeCount, ontimeCount);
                        that.$forceUpdate();
                        for (let is = 4 * c; is < c * ontimeCount + 4; is++) {
                          thislist[is]["news"] = true;
                          that.listSlide.unshift(thislist[is]);
                        }
                        setTimeout(function () {
                          that.listSlide.forEach((e) => {
                            e["news"] = false;
                          });
                          that.$forceUpdate();
                        }, 2000);
                        c++;
                        that.$forceUpdate();
                      }, 5000 * z);
                    }
                  }
                });
            }, 5000 * cishu);
          }
        });
    },
    getRandomElementsFromArray(array, numElements) {
      if (numElements > array.length) {
        throw new Error(
          "Number of elements to get is greater than array length."
        );
      }

      const shuffledArray = array.slice(); // Clone the array to avoid modifying the original
      for (let i = shuffledArray.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffledArray[i], shuffledArray[j]] = [
          shuffledArray[j],
          shuffledArray[i],
        ];
      }

      return shuffledArray.slice(0, numElements);
    },

    getColorPz(color) {
      // 蓝色
      if (color == "#007acc") {
        return require("../assets/img/box-lan.jpg");
      }
      // 紫色
      if (color == "#8a2be2") {
        return require("../assets/img/box-zi.jpg");
      }
      //  金色
      if (color == "#FFA500") {
        return require("../assets/img/box-jin.jpg");
      }
      // 红色
      if (color == "#FF4500") {
        return require("../assets/img/box-bai.jpg");
      }
    },
    transferColorToRgb(color) {
      if (typeof color !== "string" && !(color instanceof String))
        return console.error("请输入16进制字符串形式的颜色值");
      color = color.charAt(0) === "#" ? color.substring(1) : color;
      if (color.length !== 6 && color.length !== 3)
        return console.error("请输入正确的颜色值");
      if (color.length === 3) {
        color = color.replace(/(\w)(\w)(\w)/, "$1$1$2$2$3$3");
      }
      var reg = /\w{2}/g;
      var colors = color.match(reg);
      for (var i = 0; i < colors.length; i++) {
        colors[i] = parseInt(colors[i], 16).toString();
      }
      return "rgb(" + colors.join() + ")";
    },
    getBox(box_id) {
      if (!this.$store.state.loginStates) {
       return this.$message({
          showClose: true,
          message: this.$t("public.noLogin"),
          type: "error",
        });
      }
      this.$router.push({
        path: `/Openbox`,
        query: {
          box_id: box_id,
        },
      });
    },
  },
  activated() {},
  mounted() {
    this.getList();
  },
};
</script>

<style lang="less" scoped>
.slide /deep/ .el-carousel__indicators--outside {
  display: none;
}

::v-deep {
  .el-carousel__container {
    background-color: #10151b;
  }
}

.slide {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  --width: 12rem;

  // padding: 0 0;
  .slide-ul {
    position: absolute;
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
    flex-direction: column;

    .scrollAnimal {
      animation: scrollFirstChild 2s;

      @keyframes scrollFirstChild {
        0% {
          width: 0;
          opacity: 0;
        }

        100% {
          width: 140px;
          opacity: 1;
        }
      }
    }

    li:hover {
      .slide-warp {
        opacity: 0;
      }
      .slide-warp1 {
        opacity: 1;
      }
      .border {
        left: var(--width);
      }
      left: calc(0rem - var(--width));
    }

    li {
      position: relative;
      // left: 0;
      transition: all 0.5s;
      // background-image: linear-gradient(rgba(43, 44, 55, 0.5),
      // 		rgba(173, 200, 203, 0.5));
      background-size: 100% 100%;
      margin-left: 2px;
      height: 8.571rem;
      display: flex;
      flex-direction: row-reverse;
      .border {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 0.375rem;
        z-index: 99;
      }

      .slide-warp {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: var(--width);
        height: 100%;
        overflow: hidden;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        padding-left: 1.25rem;
      }
      .slide-warp {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: var(--width);
        height: 100%;
        overflow: hidden;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        padding-left: 1.25rem;
        padding-right: 1.25rem;
        padding-bottom: 0.5rem;
        box-sizing: border-box;
        opacity: 1;
        .left {
          // margin-right: 10px;

          //flex: 1 1 auto;
          img {
            height: 70px;
            width: auto;
          }
        }

        .right {
          //flex: 2 1 auto;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          display: flex;
          justify-content: center;
          .h4 {
            width: 100px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: rgb(169 172 181);
            font-weight: 400;
            font-size: 12px;
            text-align: center;
          }

          h5 {
            color: #848492;
            white-space: nowrap;
            font-weight: 200;
            font-size: 12px;

            span {
              color: #adc8cb;
              font-size: 12px;
            }
          }

          h6 {
            display: flex;
            align-items: center;

            img {
              width: 20px;
              height: 20px;
              border-radius: 50%;
            }

            span {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              margin-left: 5px;
              color: #848492;
              font-size: 12px;
            }
          }
        }
      }

      .slide-warp2 {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: var(--width);
        height: 8.571rem;
        overflow: hidden;

        .left {
          margin-right: 10px;

          //flex: 1 1 auto;
          img {
            height: 70px;
            width: auto;
          }
        }

        .right {
          //flex: 2 1 auto;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          h4 {
            width: 100px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: white;
            font-weight: 400;
            font-size: 12px;
          }

          h5 {
            color: #848492;
            white-space: nowrap;
            font-weight: 200;
            font-size: 12px;

            span {
              color: #adc8cb;
              font-size: 12px;
            }
          }

          h6 {
            display: flex;
            align-items: center;

            img {
              width: 20px;
              height: 20px;
              border-radius: 50%;
            }

            span {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              margin-left: 5px;
              color: #848492;
              font-size: 12px;
            }
          }
        }
      }
      .slide-warp3 {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 98px;
        height: 70px;
        overflow: hidden;
        background-color: #191926;

        .left {
          //flex: 1 1 auto;
          img {
            height: 50px;
            width: auto;
          }
        }

        .right {
          //flex: 2 1 auto;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          h4 {
            width: 100px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: white;
            font-weight: 400;
            font-size: 12px;
            text-align: center;
          }
        }
      }

      .slide-warp1 {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: var(--width);
        height: 100%;
        overflow: hidden;
        opacity: 0;
        padding-left: 1.25rem;
        padding-right: 1.25rem;
        padding-bottom: 0.5rem;
        box-sizing: border-box;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        .bg-img {
          opacity: 0.3;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          img {
            width: 100%;
          }
        }
        .time {
          font-size: 0.6875rem;
          opacity: 0.5;
          color: rgb(234 234 237);
        }
        .left {
          //flex: 1 1 auto;
          img {
            width: 2.25rem;
            height: 2.25rem;
            border-radius: 50%;
            border: 1px solid rgb(84 89 108);
          }
        }

        .right {
          //flex: 2 1 auto;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          h4 {
            width: 100px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: rgb(244 245 246);
            font-weight: 400;
            font-size: 12px;
            text-align: center;
          }
        }
      }

      .line {
        position: absolute;
        bottom: 4px;
        width: 100%;
        height: 2px;
        background-color: #acc7ca;
      }

      .line1 {
        background-color: #b868b3;
      }

      .line2 {
        background-color: #f1a921;
      }

      // background-color: rgba(65, 105, 161, 0.4);
      // border-bottom: 2px solid #fff;
    }

    .li1 {
      background-image: linear-gradient(
        rgba(43, 44, 55, 0.5),
        rgba(185, 105, 212, 0.5)
      );
    }

    .li2 {
      background-image: linear-gradient(
        rgba(43, 44, 55, 0.5),
        rgba(241, 169, 32, 0.5)
      );
    }
  }

  .slide-ul:hover {
    cursor: pointer;
  }

  .slide-ul1 {
    position: absolute;
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
    // animation: run57 30s;
    // // 匀速滚动
    // animation-timing-function: linear;
    // // 反向
    // animation-direction: alternate;
    // animation-iteration-count: infinite; //播放几次动画
    // animation-delay: 0s; //动画运行前等待时间
    // animation-fill-mode: forwards !important; //动画结束 是否保持

    // @keyframes run57 {
    // 	0% {
    // 		transform: translate3d(0px, 0px, 0);
    // 	}

    // 	100% {
    // 		//50
    // 		transform: translate3d(-2400px, 0px, 0);
    // 	}
    // }
    .scrollAnimal {
      animation: scrollFirstChild 2s;

      @keyframes scrollFirstChild {
        0% {
          width: 0;
          opacity: 0;
        }

        100% {
          width: 140px;
          opacity: 1;
        }
      }
    }

    // li:hover {
    // 	top: -100px;
    // }

    li {
      position: relative;
      top: 0;
      transition: all 0.5s;
      // background-image: linear-gradient(rgba(43, 44, 55, 0.5),
      // 		rgba(173, 200, 203, 0.5));
      background-size: 100% 100%;
      margin-left: 2px;
      height: 100px;

      .slide-warp {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 140px;
        height: 100px;
        overflow: hidden;

        .left {
          // margin-right: 10px;

          //flex: 1 1 auto;
          img {
            height: 70px;
            width: auto;
          }
        }

        .right {
          //flex: 2 1 auto;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          text-align: center;
          display: flex;
          justify-content: center;
          .h4 {
            width: 100px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            display: flex;
            justify-content: center;
            color: white;
            font-weight: 400;
            font-size: 12px;
            text-align: center;
          }

          h5 {
            color: #848492;
            white-space: nowrap;
            font-weight: 200;
            font-size: 12px;

            span {
              color: #adc8cb;
              font-size: 12px;
            }
          }

          h6 {
            display: flex;
            align-items: center;

            img {
              width: 20px;
              height: 20px;
              border-radius: 50%;
            }

            span {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              margin-left: 5px;
              color: #848492;
              font-size: 12px;
            }
          }
        }
      }

      .slide-warp2 {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 140px;
        height: 100px;
        overflow: hidden;

        .left {
          margin-right: 10px;

          //flex: 1 1 auto;
          img {
            height: 70px;
            width: auto;
          }
        }

        .right {
          //flex: 2 1 auto;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          h4 {
            width: 100px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: white;
            font-weight: 400;
            font-size: 12px;
          }

          h5 {
            color: #848492;
            white-space: nowrap;
            font-weight: 200;
            font-size: 12px;

            span {
              color: #adc8cb;
              font-size: 12px;
            }
          }

          h6 {
            display: flex;
            align-items: center;

            img {
              width: 20px;
              height: 20px;
              border-radius: 50%;
            }

            span {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              margin-left: 5px;
              color: #848492;
              font-size: 12px;
            }
          }
        }
      }
      .slide-warp3 {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 98px;
        height: 70px;
        overflow: hidden;
        background-color: #191926;

        .left {
          //flex: 1 1 auto;
          img {
            height: 50px;
            width: auto;
          }
        }

        .right {
          //flex: 2 1 auto;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          h4 {
            width: 100px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: white;
            font-weight: 400;
            font-size: 12px;
            text-align: center;
          }
        }
      }

      .slide-warp1 {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 140px;
        height: 100px;
        overflow: hidden;
        background-color: #191926;

        .left {
          //flex: 1 1 auto;
          img {
            height: 50px;
            width: auto;
          }
        }

        .right {
          //flex: 2 1 auto;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          h4 {
            width: 100px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: white;
            font-weight: 400;
            font-size: 12px;
            text-align: center;
          }
        }
      }

      .line {
        position: absolute;
        bottom: 4px;
        width: 100%;
        height: 2px;
        background-color: #acc7ca;
      }

      .line1 {
        background-color: #b868b3;
      }

      .line2 {
        background-color: #f1a921;
      }

      // background-color: rgba(65, 105, 161, 0.4);
      // border-bottom: 2px solid #fff;
    }

    .li1 {
      background-image: linear-gradient(
        rgba(43, 44, 55, 0.5),
        rgba(185, 105, 212, 0.5)
      );
    }

    .li2 {
      background-image: linear-gradient(
        rgba(43, 44, 55, 0.5),
        rgba(241, 169, 32, 0.5)
      );
    }
  }

  .slide-ul1:hover {
    cursor: pointer;
  }
}

.ul-line {
  height: 2px;
  background-color: #fff;
  position: absolute;
  bottom: 0px;
  width: 100%;
  z-index: -1;
}

/deep/ .el-carousel__item.is-animating {
  transition: transform 0.4s ease-in-out;
}

@media screen and (max-width: 767px) {
  .slide {
    position: relative;
    height: 70px;
    width: 100%;
    overflow: hidden;

    // padding: 0 0;
    .slide-ul {
      position: absolute;
      display: flex;
      flex-wrap: nowrap;
      .scrollAnimal {
        animation: scrollFirstChild1 2s;

        @keyframes scrollFirstChild1 {
          0% {
            width: 0;
            opacity: 0;
          }

          100% {
            width: 98px;
            opacity: 1;
          }
        }
      }
      li:hover {
        top: 0px;
      }

      li {
        position: relative;
        top: 0;
        transition: all 0.5s;
        // background-image: linear-gradient(rgba(43, 44, 55, 0.5),
        // 		rgba(173, 200, 203, 0.5));
        background-size: 100% 100%;
        margin-left: 2px;
        height: 70px;

        .slide-warp2 {
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 98px;
          height: 70px;
          overflow: hidden;

          .left {
            margin-right: 0;

            //flex: 1 1 auto;
            img {
              height: 45px;
              width: auto;
            }
          }

          .right {
            //flex: 2 1 auto;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            h4 {
              width: 78px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              color: white;
              font-weight: 400;
              font-size: 8px;
            }
          }
        }

        .line {
          position: absolute;
          bottom: 4px;
          width: 100%;
          height: 2px;
          background-color: #acc7ca;
        }

        .line1 {
          background-color: #b868b3;
        }

        .line2 {
          background-color: #f1a921;
        }

        // background-color: rgba(65, 105, 161, 0.4);
        // border-bottom: 2px solid #fff;
      }

      .li1 {
        background-image: linear-gradient(
          rgba(43, 44, 55, 0.5),
          rgba(185, 105, 212, 0.5)
        );
      }

      .li2 {
        background-image: linear-gradient(
          rgba(43, 44, 55, 0.5),
          rgba(241, 169, 32, 0.5)
        );
      }
    }

    .slide-ul:hover {
      cursor: pointer;
    }
  }
}
.iii {
  width: 78px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  // display: flex;
  // justify-content: center;
  display: block;
  color: white;
  font-weight: 400;
  font-size: 8px !important;
  text-align: center;
}
// 蓝色
.color\#007acc {
  background-image: linear-gradient(to bottom, #4b69ff 0, #2740bb 100%);
}
// 紫色
.color\#8a2be2 {
  background-image: linear-gradient(to bottom, #8847ff 0, #5e29bf 100%);
}
// 金色
.color\#FFA500 {
  background-image: linear-gradient(to bottom, #ffae39 0%, #b46d06 100%);
}
// 红色
.color\#FF4500 {
  background-image: linear-gradient(to bottom, #e34343 0%, #ca2d2d 100%);
}
</style>